// Constants
import { VueRouter } from '@/Constants/Global/VueRouter'

// Export Routes
export const ModuleRoutes = [
	{
		path: VueRouter.Modules.View10.PATH,
		name: VueRouter.Modules.View10.NAME,
		component: () => import(/* webpackChunkName: "1_10" */ '@/Views/Modules/1/10/template.vue')
	},
	{
		path: VueRouter.Modules.View11.PATH,
		name: VueRouter.Modules.View11.NAME,
		component: () => import(/* webpackChunkName: "1_11" */ '@/Views/Modules/1/11/template.vue')
	},
	{
		path: VueRouter.Modules.View12.PATH,
		name: VueRouter.Modules.View12.NAME,
		component: () => import(/* webpackChunkName: "1_12" */ '@/Views/Modules/1/12/template.vue')
	},
	{
		path: VueRouter.Modules.View13.PATH,
		name: VueRouter.Modules.View13.NAME,
		component: () => import(/* webpackChunkName: "1_13" */ '@/Views/Modules/1/13/template.vue')
	},
	{
		path: VueRouter.Modules.View20.PATH,
		name: VueRouter.Modules.View20.NAME,
		component: () => import(/* webpackChunkName: "2_20" */ '@/Views/Modules/2/20/template.vue')
	},
	{
		path: VueRouter.Modules.View21.PATH,
		name: VueRouter.Modules.View21.NAME,
		component: () => import(/* webpackChunkName: "2_21" */ '@/Views/Modules/2/21/template.vue')
	},
	{
		path: VueRouter.Modules.View22.PATH,
		name: VueRouter.Modules.View22.NAME,
		component: () => import(/* webpackChunkName: "2_22" */ '@/Views/Modules/2/22/template.vue')
	},
	{
		path: VueRouter.Modules.View23.PATH,
		name: VueRouter.Modules.View23.NAME,
		component: () => import(/* webpackChunkName: "2_23" */ '@/Views/Modules/2/23/template.vue')
	},
	// {
	// 	path: VueRouter.Modules.View30.PATH,
	// 	name: VueRouter.Modules.View30.NAME,
	// 	component: () => import(/* webpackChunkName: "3_30" */ '@/Views/Modules/3/30/template.vue')
	// },
	{
		props: true,
		path: VueRouter.Modules.View40.PATH,
		name: VueRouter.Modules.View40.NAME,
		component: () => import(/* webpackChunkName: "4_40" */ '@/Views/Modules/4/40/template.vue')
	},
	{
		path: VueRouter.Modules.View41.PATH,
		name: VueRouter.Modules.View41.NAME,
		component: () => import(/* webpackChunkName: "4_41" */ '@/Views/Modules/4/41/template.vue')
	}
]